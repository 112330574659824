* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul {
  margin-bottom: 0;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  color: #333;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1;
  overflow-x: hidden;
}

.container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 3.2rem;
}

.grid {
  gap: 8.4rem 6.4rem;
  display: grid;
}

.grid:not(:last-child) {
  margin-bottom: 8.6rem;
}

.grid--2-cols {
  grid-template-columns: repeat(2, 1fr);
}

.grid--3-cols {
  grid-template-columns: repeat(3, 1fr);
}

.grid--4-cols {
  grid-template-columns: repeat(4, 1fr);
}

.grid--5-cols {
  grid-template-columns: repeat(5, 1fr);
}

.heading-primary, .heading-secondary, .heading-tertiary {
  letter-spacing: -.5px;
  font-weight: 700;
}

.heading-primary {
  margin-bottom: 3.2rem;
  font-size: 5.2rem;
  line-height: 1.2;
}

.heading-secondary {
  color: #fff;
  background-color: #62350f;
  border-radius: 9px;
  margin-bottom: 9.6rem;
  padding: 1.2rem 2rem;
  font-size: 4.4rem;
  line-height: 1.2;
  display: inline-block;
}

.heading-tertiary {
  margin-bottom: 3.2rem;
  font-size: 3rem;
  line-height: 1.2;
}

.subheading {
  color: #62350f;
  text-transform: uppercase;
  letter-spacing: .75px;
  margin-bottom: 1.6rem;
  font-size: 1.6rem;
  font-weight: 500;
  display: block;
}

.btn, .btn:link, .btn:active {
  cursor: pointer;
  border: none;
  border-radius: 9px;
  padding: 1.6rem 3.2rem;
  font-family: inherit;
  font-size: 2rem;
  font-weight: 600;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.btn--full:link, .btn--full:visited {
  color: #333;
  background-color: #fcc419;
}

.btn--full:hover, .btn--full:active {
  color: #fff;
  background-color: #f57600;
  transform: scale(1.05);
}

.btn--outline:link, .btn--outline:visited {
  color: #555;
  background-color: #fff;
}

.btn--outline:hover, .btn--outline:active {
  color: #fdd662;
  background-color: #0000;
  box-shadow: inset 0 0 0 3px #fff;
}

.btn--form {
  color: #fdf2e9;
  background-color: #45260a;
  align-self: end;
  padding: 1.2rem;
}

.margin-right-sm {
  margin-right: 1.6rem !important;
}

@media (width <= 860px) {
  .heading-primary {
    font-size: 4rem;
  }
}

@media (width <= 780px) {
  .grid--4-cols {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}

@media (width <= 600px) {
  .header {
    padding: 0 2.4rem;
  }

  .header-logo-box {
    gap: 1rem;
  }

  .company-name {
    font-size: 1.5rem;
  }

  .logo {
    height: 3.2rem;
  }
}

@media (width <= 550px) {
  .grid--4-cols {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

/*# sourceMappingURL=index.ac878a27.css.map */
